// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-cookie-snackbar-jsx": () => import("./../../../src/pages/Components/CookieSnackbar.jsx" /* webpackChunkName: "component---src-pages-components-cookie-snackbar-jsx" */),
  "component---src-pages-components-sections-section-basics-jsx": () => import("./../../../src/pages/Components/Sections/SectionBasics.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-basics-jsx" */),
  "component---src-pages-components-sections-section-completed-examples-jsx": () => import("./../../../src/pages/Components/Sections/SectionCompletedExamples.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-completed-examples-jsx" */),
  "component---src-pages-components-sections-section-download-jsx": () => import("./../../../src/pages/Components/Sections/SectionDownload.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-download-jsx" */),
  "component---src-pages-components-sections-section-javascript-jsx": () => import("./../../../src/pages/Components/Sections/SectionJavascript.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-javascript-jsx" */),
  "component---src-pages-components-sections-section-login-jsx": () => import("./../../../src/pages/Components/Sections/SectionLogin.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-login-jsx" */),
  "component---src-pages-components-sections-section-notifications-jsx": () => import("./../../../src/pages/Components/Sections/SectionNotifications.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-notifications-jsx" */),
  "component---src-pages-components-sections-section-pills-jsx": () => import("./../../../src/pages/Components/Sections/SectionPills.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-pills-jsx" */),
  "component---src-pages-components-sections-section-tabs-jsx": () => import("./../../../src/pages/Components/Sections/SectionTabs.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-tabs-jsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-page-contact-page-jsx": () => import("./../../../src/pages/ContactPage/ContactPage.jsx" /* webpackChunkName: "component---src-pages-contact-page-contact-page-jsx" */),
  "component---src-pages-contact-page-sections-contact-section-jsx": () => import("./../../../src/pages/ContactPage/Sections/ContactSection.jsx" /* webpackChunkName: "component---src-pages-contact-page-sections-contact-section-jsx" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-datenschutz-page-datenschutz-page-jsx": () => import("./../../../src/pages/DatenschutzPage/DatenschutzPage.jsx" /* webpackChunkName: "component---src-pages-datenschutz-page-datenschutz-page-jsx" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-impressum-page-impressum-page-jsx": () => import("./../../../src/pages/ImpressumPage/ImpressumPage.jsx" /* webpackChunkName: "component---src-pages-impressum-page-impressum-page-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-js": () => import("./../../../src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-landing-page-landing-page-jsx": () => import("./../../../src/pages/LandingPage/LandingPage.jsx" /* webpackChunkName: "component---src-pages-landing-page-landing-page-jsx" */),
  "component---src-pages-landing-page-sections-product-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/ProductSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-product-section-jsx" */),
  "component---src-pages-landing-page-sections-section-carousel-jsx": () => import("./../../../src/pages/LandingPage/Sections/SectionCarousel.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-section-carousel-jsx" */),
  "component---src-pages-landing-page-sections-team-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/TeamSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-team-section-jsx" */),
  "component---src-pages-landing-page-sections-to-go-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/ToGoSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-to-go-section-jsx" */),
  "component---src-pages-landing-page-sections-welcome-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/WelcomeSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-welcome-section-jsx" */),
  "component---src-pages-landing-page-sections-work-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/WorkSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-work-section-jsx" */),
  "component---src-pages-lieferservice-js": () => import("./../../../src/pages/lieferservice.js" /* webpackChunkName: "component---src-pages-lieferservice-js" */),
  "component---src-pages-lieferservice-page-lieferservice-page-jsx": () => import("./../../../src/pages/LieferservicePage/LieferservicePage.jsx" /* webpackChunkName: "component---src-pages-lieferservice-page-lieferservice-page-jsx" */),
  "component---src-pages-stadl-js": () => import("./../../../src/pages/stadl.js" /* webpackChunkName: "component---src-pages-stadl-js" */),
  "component---src-pages-stadl-page-stadl-page-jsx": () => import("./../../../src/pages/StadlPage/StadlPage.jsx" /* webpackChunkName: "component---src-pages-stadl-page-stadl-page-jsx" */),
  "component---src-pages-wirtshaus-js": () => import("./../../../src/pages/wirtshaus.js" /* webpackChunkName: "component---src-pages-wirtshaus-js" */),
  "component---src-pages-wirtshaus-page-wirtshaus-page-jsx": () => import("./../../../src/pages/WirtshausPage/WirtshausPage.jsx" /* webpackChunkName: "component---src-pages-wirtshaus-page-wirtshaus-page-jsx" */)
}

